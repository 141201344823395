<script>
export default {
	data() {
		return {
			rewards: [],
			seletedCategories: [],
			categories: [
				{
					name: 'Celulares',
					tag: 'celulares',
					icon: '/assets/categorias/celulares.png',
				},
				{
					name: 'Videojuegos',
					tag: 'videojuegos',
					icon: '/assets/categorias/videojuegos.png',
				},
				{
					name: 'Pantallas',
					tag: 'pantallas',
					icon: '/assets/categorias/pantallas.png',
				},
				{
					name: 'Laptops',
					tag: 'laptops',
					icon: '/assets/categorias/laptops.png',
				},
				{
					name: 'Muebles',
					tag: 'muebles',
					icon: '/assets/categorias/muebles.png',
				},
				{
					name: 'Audios',
					tag: 'audios',
					icon: '/assets/categorias/audios.png',
				},
				{
					name: 'Hogar',
					tag: 'hogar',
					icon: '/assets/categorias/hogar.png',
				},
			],
			products: [
				{
					id: 1,
					img: '/assets/productos/producto_1.jpg',
					name: 'Motocicleta Doble Propósito Italika DM250 Azul',
					category: 'hogar',
					price: 262
				},
				{
					id: 2,
					img: '/assets/productos/producto_2.jpg',
					name: 'Iphone 8',
					category: 'celulares',
					price: 91
				},
				{
					id: 3,
					img: '/assets/productos/producto_3.png',
					name: 'Laptop Hp',
					category: 'laptops',
					price: 77
				},
				{
					id: 4,
					img: '/assets/productos/producto_4.jpg',
					name: 'Motorola Moto G9  + Audífonos ',
					category: 'celulares',
					price: 44
				},
				{
					id: 5,
					img: '/assets/productos/producto_5.png',
					name: 'Cámara Fotográfica Digital Canon',
					category: 'hogar',
					price: 35
				},
				{
					id: 6,
					img: '/assets/productos/producto_6.jpg',
					name: 'Tablet Samsung ',
					category: 'celulares',
					price: 39
				},
				{
					id: 7,
					img: '/assets/productos/producto_7.jpg',
					name: 'Audífonos Inalámbricos Motorola ',
					category: 'audios',
					price: 9
				},
				{
					id: 8,
					img: '/assets/productos/producto_8.jpg',
					name: 'Pantalla LED LG',
					category: 'pantallas',
					price: 100
				},
				{
					id: 9,
					img: '/assets/productos/producto_9.png',
					name: 'Reproductor Blu-ray Sony',
					category: 'pantallas',
					price: 20
				},
				{
					id: 10,
					img: '/assets/productos/producto_10.png',
					name: 'Teatro en casa LG',
					category: 'audios',
					price: 34
				},
				{
					id: 11,
					img: '/assets/productos/producto_11.png',
					name: 'Horno de Microondas Whirpool',
					category: 'hogar',
					price: 35
				},
				{
					id: 12,
					img: '/assets/productos/producto_12.png',
					name: 'Licuadora Oster',
					category: 'hogar',
					price: 10
				},
				{
					id: 13,
					img: '/assets/productos/producto_13.png',
					name: 'Horno tostador Procto Silex',
					category: 'hogar',
					price: 9
				},
				{
					id: 14,
					img: '/assets/productos/producto_14.png',
					name: 'Tostador Oster',
					category: 'hogar',
					price: 6
				},
				{
					id: 15,
					img: '/assets/productos/producto_15.png',
					name: 'Cafetera T-FAL',
					category: 'hogar',
					price: 6
				},
				{
					id: 16,
					img: '/assets/productos/producto_16.png',
					name: 'Plancha Oster',
					category: 'hogar',
					price: 10
				},
				{
					id: 17,
					img: '/assets/productos/producto_17.png',
					name: 'Aspiradora Koblenz',
					category: 'hogar',
					price: 18
				},
				{
					id: 18,
					img: '/assets/productos/producto_18.png',
					name: 'Batidora Black and Decker',
					category: 'hogar',
					price: 11
				},
				{
					id: 19,
					img: '/assets/productos/producto_19.png',
					name: 'Extractor Hamilton Beach',
					category: 'hogar',
					price: 12
				},
				{
					id: 20,
					img: '/assets/productos/producto_20.png',
					name: 'Sandwichera Taurus ',
					category: 'hogar',
					price: 6
				},
			],
			query: '',
		}
	},
	computed: {
		results() {
			let vm = this
			let data = this.products
			if(vm.seletedCategories.length) {
				data = data.filter(p => vm.seletedCategories.includes(p.category) )
			}
			if(vm.query) {
				data = data.filter(p => p.name.toLowerCase().includes(vm.query.toLowerCase()) || p.category.toLowerCase().includes(vm.query.toLowerCase()))
			}
			return data
		},
	},

	methods: {
		setCategory(tag) {
			let vm = this
			if(vm.seletedCategories.includes(tag)) {
				vm.seletedCategories.splice(vm.seletedCategories.indexOf(tag), 1)
			} else {
				vm.seletedCategories.push(tag)
			}
		}
	}
}
</script>

<template>
	<div class="vault">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper text-center">
					<img class="vault__header-word" src="/assets/pantalla_boveda/titulo_boveda.png" alt="">
					<div class="score__coins">
						<span class="score__coins-text">320</span>
						<img class="score__coins-bg" src="/assets/pantalla_perfil_puntaje/conteo_monedas.png" alt="">
					</div>
				</div>
			</div>

		</div>
		<div class="vault__search mt-1">
			<div class="vault__search-input">
				<input type="text" v-model="query" placeholder="Buscar premio">
				<button class="">
					<i class="fa fa-search" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<div class="vault__categories mt-1">
			<button class="vault__category" :class="{
				'vault__category--mark': seletedCategories.length,
				'vault__category--selected': seletedCategories.includes(c.tag),
			}" v-for="(c, index) in categories" :key="`c_${index}`" @click="setCategory(c.tag)">
				<img :src="c.icon" alt="">
			</button>
		</div>
		
		<div class="vault__wrapper mt-3">
			<div v-if="results.length" class="vault__list">
				<div v-for="(reward, index) in results"  :key="`r-${index}-${reward.id}`" class="vault__reward" :style="{
					'animation-delay': (0.3 * index )+ 0.3  + 's'
				}">
					<div class="vault__reward-content">
						<div class="vault__reward-image col-4 p-0">
							<img :src="reward.img" alt="">
						</div>
						<div class="vault__reward-name col-8">
							<p class="m-0">{{reward.name}}</p>
							<div class="d-flex">
								<button class="btn btn-primary btn--rounded btn-sm vault__reward-action ">
									<span class="fw-bold text-white">G{{reward.price}} COMPRAR</span> 
								</button>
							</div>
						</div>
						<!-- <div class="vault__reward-action col-4 p-0 ms-2">
							<button>
								<span class="price">
								G{{reward.price}}
								</span>
								<span class="d-block">
									COMPRAR
								</span>
							</button>
						</div> -->
					</div>
				</div>
			</div>
			<div class="text-center " v-else>
				<img class="w-50" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				<h3>No encontré  lo que buscas...</h3>
			</div>
		</div>
		<div class="vault__bg">
			<div class="vault__d">
				<img src="/assets/pantalla_boveda/fondo_boveda.png" alt="">
			</div>
		</div>
	</div>
</template>