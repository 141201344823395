<script>
export default {
	data() {
		return {
			classStart: '',
		}
	},

	mounted() {
		let vm = this
		setTimeout(() => {
			vm.classStart = 'start--show'
		}, 2000)
	}
}
</script>
<template>
	<div class="start" :class="classStart">
		<div class="start__top">
			<img class="start__robot start__robot--left" src="/assets/pantalla_inicio/robot_pequeno_izquierda.png" alt="">
			<img class="start__robot start__robot--rigth" src="/assets/pantalla_inicio/robot_pequeno_derecha.png" alt="">
			<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
		</div>
		<div class="start__bottom">
			<div class="start__content">
				<div class="start__bottom__slogan mb-4 mx-auto">
					<img class="start__bottom__slogan-pin" src="/assets/logo_verde.png" alt="">
				</div>
				<router-link :to="{name: 'Score'}" class="btn btn-primary btn--rounded w-100">
					<span class="fw-bold">
						INICIAR JUEGO
					</span>
				</router-link>
			</div>
			<!-- <div class="start__logo">
				<img src="/assets/pantalla_inicio/logo_zeus.png" alt="">
			</div> -->
		</div>
	</div>
</template>