<script>
import Loader from '@/components/Loader.vue'

export default {
	components: {
		Loader
	},

	mounted() {
	 	this.$refs.splashLoader.handlerShow()
		var images = [];
		function preload(imageslist) {
			for (var i = 0; i < imageslist.length; i++) {
				images[i] = new Image();
				images[i].src = imageslist[i];
			}
		}

		//-- usage --//
		preload([
			"./assets/cara_robot_cargador.png",
			"./assets/logo_zeus.png",
			"./assets/pantalla_inicio/robot_pequeno_izquierda.png",
			"./assets/pantalla_inicio/robot_pequeno_derecha.png",
			"./assets/pantalla_inicio/robot_grande_central.png",
			"./assets/pantalla_inicio/palabra_baz.png",
			"./assets/pantalla_inicio/logo_zeus.png",
			"./assets/pantalla_perfil_puntaje/insignia_principal.png",
			"./assets/pantalla_perfil_puntaje/btn_2.png",
			"./assets/pantalla_perfil_puntaje/btn_1.png",
			"./assets/pantalla_perfil_puntaje/conteo_monedas.png",
		])
	}
}
</script>

<template>
  <div id="app">
	<button class="app-back" v-if="!['Start', 'Download'].includes($route.name)" @click="$router.go(-1)">
		<img src="/assets/pantalla_perfil_puntaje/btn_2.png" alt="">
	</button>
	<transition name="slide">
    	<router-view :key="$router.name"/>
	</transition>
	<Loader ref="splashLoader"/>
  </div>
</template>

<style lang="scss">
@import './assets/scss/app.scss';
</style>
