import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Score from '../views/Score.vue'
import Vault from '../views/Vault.vue'
import Download from '../views/Download.vue'


Vue.use(VueRouter)

const routes = [
//   {
//     path: '/',
//     name: 'Start',
//     component: Start
//   },
//   {
//     path: '/score',
//     name: 'Score',
//     component: Score
//   },
//   {
//     path: '/vault',
//     name: 'Vault',
//     component: Vault
//   },
  {
    path: '/',
    name: 'Download',
    component: Download
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
