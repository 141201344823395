<script>
export default {
	data() {
		return {
			percentage: 0,
			step: 0.5,
			current_progress: 0,
			interval: null,
			show: true,
		}
	},

	methods: {
		handlerShow() {
			this.show = true
			this.runLoader()
		},

		handlerHide() {
			this.show = false
			if(this.interval) {
				clearInterval(this.interval)
			}
		},
		runLoader() {
			let vm = this
			vm.interval = setInterval(function() {
				vm.current_progress += vm.step;
				vm.percentage = Math.round(Math.atan(vm.current_progress) / (Math.PI / 2) * 100 * 1000) / 700

				if (vm.percentage >= 100){
					setTimeout(() => {
						vm.handlerHide()
					}, 1000)
				}else if(vm.percentage >= 90) {
					this.step = 0.5
				}else if(vm.percentage >= 70) {
					this.step = 0.1
				}
			}, 100)
		}
	},
}
</script>
<template>
	<div v-if="show" class="splash-loader">
		<div class="splash-loader__content">
			<div class="splash-loader__slogan mb-3">
				<img class="splash-loader__spin" src="/assets/logo_blanco.png" alt="">
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-striped bg-special-3" role="progressbar" :style="{
					width: `${percentage}%`
				}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<h3 class="text-center splash-loader__title">CARGANDO</h3>

		</div>


		<div class="splash-loader__logo">
			<img src="/assets/logo_zeus.png" alt="">
		</div>
	</div>
</template>